@import "bootstrap/scss/bootstrap";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

html, body {
  height: 100%;
  font-size: 13px;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: $bg;
  color: $text;
  overflow: hidden;
}

.topbar {
  height: 60px;
  max-height: 60px;
  width: 100%;
  background-color: $bg;

  a, .btn-link {
    color: $topbar-text !important;
  }
}

.titlebar {
  height: 95px;
  max-height: 95px;
  width: 100%;
  background-color: $white;
  padding-left: 2rem;
  padding-top: 1.5rem;
}

.titlebar, .modal-header {
  h1, h2, h3, h4, h5 {
    font-weight: 300;

    small {
      font-weight: 400;
      font-size: 65%;
    }
  }
}

.titlebar {
  h1, h2, h3, h4, h5 {
    margin-bottom: 0.75rem;
  }
}

.pagebody {
  position: relative;
  margin: 1rem 1rem 1rem 1rem;
  padding: 1rem 1rem 1rem 1rem;
  width: calc(100% - 2rem);
  max-height: calc(100vh - 2rem - 155px); //subtract padding plus title bar and top bar height
  min-height: calc(100vh - 2rem - 155px);
  height: calc(100vh - 2rem - 155px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;
  display: flex !important;
  flex-direction: column !important;
  &.no-topbar {
    max-height: calc(100vh - 2rem - 95px); //subtract padding plus top bar height
    min-height: calc(100vh - 2rem - 95px);
    height: calc(100vh - 2rem - 95px);
  }
  &.pagebody-cardlayout {
    padding: 0;
    background-color: unset;
  }
}

.pagebody-tab {
  padding-top: 0.5rem !important;

  .tab-pane {
    padding-top: 0.5rem; //so room for highlighting
    max-height: calc(100vh - 2rem - 155px - 2.5rem - 34.5px); //subtract pagebody height, padding and tab height
    min-height: calc(100vh - 2rem - 155px - 2.5rem - 34.5px);
    height: calc(100vh - 2rem - 155px - 2.5rem - 34.5px);
    overflow-x: hidden;

    &:has(.footer-container-body) {
      overflow-y: hidden;
    }

    &:not(:has(.footer-container-body)) {
      padding-right: 0.5rem;
      overflow-y: auto;
    }

    .footer-container {
      max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px);
      min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px);
      height: calc(100vh - 2rem - 155px - 3rem - 34.5px);
    }
  }
}

.footer-container {
  max-height: calc(100vh - 4rem - 155px); //subtract padding plus title bar and top bar height
  min-height: calc(100vh - 4rem - 155px);
  height: calc(100vh - 4rem - 155px);
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex !important;
  flex-direction: column !important;

  .pagebody-tab > .tab-pane {
    max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 3.5rem);
    min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 3.5rem);
    height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 3.5rem);
    padding-bottom: 0.5rem;
  }
}

.footer-container-body {
  padding-right: 0.5rem;
  flex-grow: 1 !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer-container-footer {
  min-height: 3.5rem;
  overflow: hidden;
  flex: none;

  .row {
    height: 100% !important;
    align-items: end !important;
  }
}

a, .btn-link {
  font-size: unset !important;
  text-align: unset !important;
}

mark, .mark {
  background: unset;
  color: unset;
  background-color: unset;
  padding: 0 !important;
}

.text-issues {
  .viewer-column {
    max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem);
    min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem);
    height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem);
    overflow-y: auto;

    .viewer {
      &:not(.editTags) {
        &:not(.subjCommtRefs) {
          max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 105.06px);
          min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 105.06px);
          height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 105.06px);
        }

        &.subjCommtRefs {
          max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 106.63px);
          min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 106.63px);
          height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 106.63px);
        }
      }

      &.editTags {
        &:not(.subjCommtRefs) {
          max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 132.31px);
          min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 132.31px);
          height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 132.31px);
        }

        &.subjCommtRefs {
          max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 133.88px);
          min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 133.88px);
          height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem - 133.88px);
        }
      }

      overflow: auto;

      &.markKeyword .keywordMark {
        color: $white;
        background-color: $keyword;

        .diffEdit, .diffAdd {
          color: $white !important;
          background-color: $keyword !important;
        }
      }

      &.markAdd .diffMark.diffAdd {
        color: $white;
        background-color: $diff-new;
      }

      &.markEdit .diffMark.diffEdit {
        color: $white;
        background-color: $diff-update;
      }

      &.markRemove {
        .diffMark.diffRemoveBefore:before, .diffMark.diffRemoveAfter:after {
          font-family: 'Font Awesome 6 Free', serif;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: var(--fa-display, inline-block);
          font-style: normal;
          font-variant: normal;
          font-weight: 900;
          line-height: 1;
          text-rendering: auto;
          content: '\f056';
          font-size: 16px;
          color: $diff-delete
        }
      }

      &.markCited {
        mark, .mark {
          padding: 0 !important;
          cursor: pointer;

          &.citationMark {
            background-color: $cited;
            color: $text;

            .diffMark, .diffEdit, .diffAdd, .keywordMark {
              background-color: unset;
              color: unset;
              cursor: unset;
              padding: unset;
            }
          }

          &.selected {
            background-color: $primary !important;
            color: $white !important;

            .diffEdit, .diffAdd {
              background-color: $primary !important;
              color: $white !important;
            }

            .keywordMark {
              background-color: $primary !important;
              color: $white !important;

              .diffEdit, .diffAdd {
                background-color: $primary !important;
                color: $white !important;
              }
            }

            .citationMark {
              background-color: unset;
              color: unset;
            }
          }
        }
      }

      &.editable {
        display: inline-block;
        width: 100%;
      }

      .new, .keywordMark .new, .citationMark .new, .diffMark .new {
        background-color: $primary !important;
        color: $white !important;

        .keywordMark, .diffMark, .citationMark {
          background-color: unset;
          color: unset;
        }
      }
    }

    .bottom-docked {
      position: absolute;
      bottom: 0;
      width: calc(100% - 2rem);
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    ::selection {
      color: $white !important;
      background-color: $primary !important;
    }
  }

  .issue-mapping-column {
    @media (max-width: 1199px) {
      max-height: calc((100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem) / 2);
      min-height: calc((100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem) / 2);
      height: calc((100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem) / 2);
      overflow-y: auto;
    }
    @media (min-width: 1200px) {
      max-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem);
      min-height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem);
      height: calc(100vh - 2rem - 155px - 3rem - 34.5px - 4.5rem);
      overflow-y: auto;
    }
  }
}

.card-header {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $text;
  }

  h1, h2, h3, h4, h5 {
    font-weight: 600;
  }
}

.card-footer {
  border-top-width: 0
}

.dashboard {
  .card:not(.dataTables_processing) {
    border-width: 0;

    .card-header {
      padding-top: 1rem;

      h5.card-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.08rem;
      }
    }

    .card-body {
      .metric-count {
        font-weight: 100;
        font-size: 1.9rem;

        &.strong {
          font-weight: 400
        }
      }

      .progress-sm {
        height: 0.4rem;
      }
    }

    .card-footer {
      padding-top: 0;
    }
  }
}

.modal-header {
  h1, h2, h3, h4, h5 {
    font-weight: 100;
  }

  .modal-title {
    margin-right: auto;
  }

  .btn-close {
    margin-left: 0 !important;
  }
}

.modal-body {
  background-color: $modal-body-bg;
}

.tos {
  .modal-header {
    padding: 2rem 2rem 1.3rem 2rem;

    h1, h2, h3, h4, h5 {
      margin-bottom: 0;
      padding-bottom: 0.6rem;
    }
  }

  .modal-body {
    h1, h2, h3, h4, h5 {
      font-weight: 600;
      color: $primary;
    }
  }
}

ngb-popover-window, div.popover {
  font-family: 'Open Sans', sans-serif !important;

  h3.popover-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem !important;
    font-weight: 700;
    color: $text !important;
    background-color: $bg !important;
  }

  .popover-body {
    font-size: 1rem !important;
    color: $text !important;
  }
}

a, .btn-link {
  cursor: pointer;
  color: $primary;
  text-decoration: none !important;

  &.form-control-plaintext {
    color: $primary;
  }

  &.disabled {
    color: $text-muted;
  }

  &:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: $primary;
  }
}

i[class] {
  color: $light-text
}

button, a, .btn, .toast-header {
  i[class] {
    color: unset;
  }
}

.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.08rem;

  &.btn-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.385rem;
    padding-right: 0.385rem;
  }
}

button:disabled, button.disabled, .btn:disabled, .btn.disabled {
  cursor: not-allowed;
  pointer-events: unset;

  &:active {
    color: var(--bs-btn-disabled-color);
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
  }
}

input.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.badge.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

input.readonly {
  cursor: not-allowed !important;
}

//Bootstrap overrides

strong {
  font-weight: 600;
}

.invalid-feedback {
  font-size: unset;
  font-weight: 700;
}

.breadcrumb-item.active {
  font-weight: 700;

  &:before {
    font-weight: 400;
  }
}

.tab-content {
  padding-top: 1rem;
}

.tab-icon {
  vertical-align: -0.2rem;
}

.toast-header {
  border-bottom-width: 0;
  padding: 0.5rem 0.75rem 0 0.75rem;
  font-weight: 700;
  font-size: 1rem;
  color: unset;
  background-color: unset;

  button {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

.toast-body {
  padding: 0 0.75rem 0.5rem 0.75rem;
  font-size: 1rem;
}

.col-form-label {
  font-weight: 700;
  text-align: right;
  padding-right: 2rem;
}

.col-form-heading {
  font-weight: 700;
  text-align: left;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
}

.form-control, .form-select, option, quill-editor .ql-editor {
  font-size: 1.08rem !important;
  color: $text;
}

.dropdown-item {
  color: $text;

  &:hover {
    color: $white;
    background-color: $primary;
  }
}

ul.dropdown-menu {
  li:has(a.disabled) {
    cursor: not-allowed;
  }
}

.form-control-plaintext {
  padding: 0.375rem 0;

  &:not(.invalid-feedback) {
    font-size: 1.08rem !important;
  }

  &.invalid-feedback {
    display: block;
    margin-top: 0;
    color: var(--bs-danger);
    font-size: 1rem !important;
  }
}

.form-badge {
  margin-top: 0.5em;
}

.select2-selection__rendered {
  color: $text !important;

  &:has(.select2-selection__clear) {
    padding-right: 1.25rem !important; //stop x overlapping with text
  }
}

.select2-container--bootstrap-5, .select2-container--default {
  .select2-selection, .select2-dropdown .select2-results__options .select2-results__option, .select2-dropdown .select2-search .select2-search__field {
    font-size: 1.08rem !important;
    color: $text !important;

    &.select2-results__option--selected, &[aria-selected="true"]:not(.select2-results__option--highlighted) {
      color: #ffffff !important;
    }
  }

  .select2-dropdown {
    border-color: $input-focus-border-color !important;
  }

  .select2-dropdown .select2-search .select2-search__field:focus, &.select2-container--focus .select2-selection, &.select2-container--open .select2-selection {
    border-color: $input-focus-border-color !important;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.25) !important;
  }
}

.is-invalid {
  .select2-container--bootstrap-5, .select2-container--default {
    .select2-selection, .select2-dropdown {
      border-color: $form-feedback-invalid-color !important;
    }

    .select2-dropdown .select2-search .select2-search__field:focus, &.select2-container--focus .select2-selection, &.select2-container--open .select2-selection {
      border-color: $form-feedback-invalid-color !important;
      box-shadow: 0 0 0 0.25rem rgba($form-feedback-invalid-color, 0.25) !important;
    }
  }
}

.nav-tabs {
  .nav-link {
    color: unset;

    &.active {
      font-weight: 600;
      color: unset;
    }
  }
}

.help-block {
  font-weight: 700;
  color: #a7aaab;
}

//datatables
table.dataTable > thead .sorting:after, table.dataTable > thead .sorting_asc:after, table.dataTable > thead .sorting_desc:after, table.dataTable > thead .sorting_asc_disabled:after, table.dataTable > thead .sorting_desc_disabled:after {
  content: "\f063" !important;
  font: var(--fa-font-solid);
  padding-bottom: 4px;
  opacity: 0.5 !important;
}

table.dataTable > thead .sorting:before, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:before {
  content: "\f062" !important;
  font: var(--fa-font-solid);
  margin-right: 5px;
  padding-bottom: 4px;
  opacity: 0.5 !important;
}

table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 1 !important;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc {
  font-style: italic;
}

.base-clusters-table .dataTables_empty,
.group-clusters-table .dataTables_empty,
.comments-table .dataTables_empty {
  display: none;
}

.group-clusters-table td .fa-chart-pie::before {
  font-size: 15px;
}

div.dataTables_wrapper {
  .dt-row {
    position: inherit !important;
  }

  .row:not(.dt-row) {
    height: 38px;
  }

  .dataTables_length {
    height: 38px;
  }

  .dataTables_filter {
    label {
      font-size: 0;

      input {
        margin-bottom: 5px;
      }
    }
  }

  .dataTables_info {
    display: inline-block;
  }

  .dataTables_paginate {
    display: inline-block;
    float: right;
  }
}

div.dataTables_processing {
  z-index: 10;

  > div:last-child > div {
    background: $primary !important;
  }
}

div.dataTables_paginate > ul.pagination > li.paginate_button {
  a {
    border: 0 !important;
  }

  &.disabled > a {
    background-color: unset;
    color: $text-muted;
  }
}

td {
  vertical-align: middle;
}

table.footer-top {
  border-collapse: separate !important;
  border-spacing: 0 !important;

  tfoot {
    display: table-header-group;
  }
}

table.dataTable.table-striped > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.03) !important;
}

table.dataTable.table-hover > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.075) !important;
}

.table-length-container label select {
  display: inline-block;
  width: auto;
}

.table.no-background > :not(caption) > * > * {
  background-color: unset;
}

.table td .list-group .list-group-item-transparent {
  background-color: unset;

  &.list-group-item-action:hover {
    background-color: var(--bs-list-group-action-hover-bg);
  }
}

.chart-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0;
}

//sidebar
.page-wrapper {
  height: 100%;

  @media screen and (min-width: 768px) {
    &:not(.toggled) {
      .page-content {
        padding-left: 260px;
      }

      .sidebar {
        width: 260px;

        .sidebar-content {
          .sidebar-header {
            margin-left: 25px !important;
            margin-right: 25px !important;

            .sidebar-img-container {
              padding-bottom: 50%;

              .sidebar-img {
                background-image: url('../assets/logo/2x1_main_inverse.png');
              }
            }

            .sidebar-account {
              display: block;
             .muted {
               color: $sidebar-text-muted;
              }
              :not(.muted) {
                color: $sidebar-text;
              }
              a.dropdown-toggle {
                &.muted {
                  color: $sidebar-text-muted;
                }
                &:not(.muted) {
                  color: $sidebar-text;
                }
              }
              a.dropdown-item {
                color: $text;
                &:hover {
                  color: $white;
                  background-color: $primary;
                }
              }
            }
          }

          .sidebar-body {
            .menuitem-title {
              display: inline;
              white-space: pre-wrap;
            }

            .sm {
              font-size: 12px;
            }

            .submenu {
              position: static;
              padding: 0 !important;
              width: unset;
            }

            .dropdown-toggle:after {
              right: 30px;
              font-size: 1.25em;
            }

            ul {
              li {
                a {
                  padding: 8px 55px 8px calc(20px * var(--data-level));
                }
              }
            }
          }

          .sidebar-footer {
            display: block;
            padding-bottom: 1.5rem;
            color: $sidebar-text-muted;
            a {
              color: $sidebar-text-muted;
            }
          }
        }
      }
    }
  }

  .btn-toggle {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .page-content {
    display: inline-block;
    padding-left: 70px;
    width: 100%;
    transition: padding-left .5s ease;
  }

  .sidebar {
    width: 70px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: width .5s ease;
    background-color: $sidebar-bg;
    color: $sidebar-text;

    .sidebar-content {
      margin-top: 0.5rem !important;
      display: flex !important;
      flex-direction: column !important;
      height: 100vh;
      overflow-x: hidden;

      .sidebar-header {
        margin-left: 12px !important;
        margin-right: 12px !important;
        padding-top: 0.5rem;

        .sidebar-img-container {
          position: relative;
          width: 100%;
          padding-bottom: 100%;

          .sidebar-img {
            background-image: url('../assets/logo/1x1_main_inverse.png');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }

        .sidebar-account {
          display: none;
        }
      }

      .sidebar-body {
        padding-top: 0.5rem;
        flex-grow: 1 !important;

        .hide-inactive {
          display: none;

          &.active {
            display: inline-block;
          }
        }

        .active {
          i {
            color: $sidebar-text-active;
          }

          .menuitem-title {
            font-weight: 600;
            color: $sidebar-text-active;
          }
        }

        .progress-bar {
          width: 0;
          -webkit-transition: width 0.5s ease-in-out;
          -moz-transition: width 0.5s ease-in-out;
          -o-transition: width 0.5s ease-in-out;
          transition: width 0.5s ease-in-out;
        }

        .menuitem-title {
          display: none;
        }

        > div > ul > app-sidebar-menu > li > .submenu { //only catch first level submenu
          padding: 0 0.5rem 0 1rem !important;
          background: $sidebar-bg;
        }

        ul li:hover > a {
          span {
            color: $sidebar-text-hover;
          }

          i {
            color: $sidebar-text-hover;
          }
        }

        a {
          text-decoration: none;
          transition: color .3s ease;
          color: $sidebar-text-muted !important;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            a {
              display: inline-block;
              width: 100%;
              text-decoration: none;
              position: relative;
              padding: 8px 42px 8px 12px;

              &.dropdown-toggle:after {
                display: block;
                position: absolute;
                top: 50%;
                right: 20px;
                font-size: 1em;
                transform: rotate(90deg);
              }

              &.dropdown-toggle[aria-expanded="true"]:after {
                transform: rotate(0deg);
              }

              &.dropdown-toggle:after {
                transition: 0.5s;
              }

              &:hover > i::before {
                display: inline-block;
                animation: swing ease-in-out .5s 1 alternate;
              }

              span.label, span.badge {
                float: right;
                margin-top: 8px;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .sidebar-footer {
        display: none;
      }
    }
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

//misc
.login .card-body {
  padding: 2rem 2rem;
}

.form-check.form-check-md {
  padding-left: 2em !important;

  .form-check-input {
    height: 1.5em;
    width: 1.5em;
    margin-left: -2em;
  }

  .form-check-label {
    margin-top: 0.25em;

    &.badge {
      margin-top: 0.5em;
    }
  }
}

td.checkbox-center {
  .form-check, .form-check input.form-check-input {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.form-check-input {
  &.form-check-input-md {
    height: 1.5em;
    width: 1.5em;
  }

  &.is-invalid {
    border-width: 2px;
  }
}


.form-control-inline {
  display: inline-block;
  width: auto;
}

label {
  cursor: text;

  &.required {
    padding-right: calc(2rem - 11px);

    &:after {
      content: ' *';
    }
  }
}

.modal-body ng-scrollbar {
  max-height: 70vh;
  overflow-y: auto;
}

i[class]:not(:only-child) {
  margin-right: 0.5rem;
}

button:not(:last-of-type) {
  margin-right: 0.25rem;
}

.badge:not(:last-of-type) {
  margin-right: 0.25rem;
}

.task-badge {
  padding: 0.5rem !important;
  opacity: 0.7;
  font-size: 1rem;
  font-weight: 400;

  &:hover {
    opacity: 1;
  }
}

.bg-primary-reverse {
  padding: 2px 6px 3px 6px !important;
  background-color: white !important;
  color: $primary !important;
  border: 2px solid $primary;
}

.bg-secondary-reverse {
  padding: 2px 6px 3px 6px !important;
  background-color: white !important;
  color: $secondary !important;
  border: 2px solid $secondary;
}

.bg-primary-secondary {
  background-image: repeating-linear-gradient(-45deg, $primary, $primary 4px, $secondary 4px, $secondary 8px);
  color: white;
}

.bg-primary-secondary-reverse {
  padding: 2px 6px 3px 6px !important;
  background-color: white !important;
  color: $primary !important;
  border: 2px solid $secondary;
}

.fg-dark {
  color: black;
}

.bg-rule-cnt {
  background-color: $keyword !important;
}


.badge {
  &.outline-good {
    color: $good;
    border: 1px solid $good;

    &.bg-good {
      color: white !important;
    }
  }

  &.outline-ok {
    color: $ok;
    border: 1px solid $ok;

    &.bg-ok {
      color: white !important;
    }
  }

  &.outline-bad {
    color: $bad;
    border: 1px solid $bad;

    &.bg-bad {
      color: white !important;
    }
  }

  &.outline-worst {
    color: $worst;
    border: 1px solid $worst;

    &.bg-worst {
      color: white !important;
    }
  }

  &.outline-warn {
    color: black;
    border: 1px solid $warn;
  }

  &.outline-keyword {
    color: $keyword;
    border: 1px solid $keyword;

    &.bg-keyword {
      color: white !important;
    }
  }

  &.outline-cited {
    color: $text;
    border: 2px solid $cited;

    &.bg-cited {
      color: $text;
    }
  }
}

$custom-colors: (
  white: $white,
  light-text: $light-text,
  best: $best,
  good: $good,
  bad: $bad,
  ok: $ok,
  worst: $worst,
  warn: $warn,
  na: $na,
  roles: $roles,
  comments: $comments,
  other-comments: $other-comments,
  stages: $stages,
  counts: $counts,
  status: $status,
  connxns: $connxns,
  tag: $tag,
  cited: $cited,
  keyword: $keyword,
  diff-new: $diff-new,
  diff-update: $diff-update,
  diff-delete: $diff-delete,
  reporting: $reporting
);
@each $key, $val in $custom-colors {
  .fg-#{"" + $key} {
    color: $val !important;
  }
  .bg-#{"" + $key} {
    background-color: $val !important;
  }
  .outline-#{"" + $key} {
    border: 1px solid $val;
    color: $val;
  }
}

//  table
.table > :not(:first-child) {
  border-top: none;
}

table {
  th.rotate {
    height: 100px;
  }

  th.rotate > div {
    -webkit-transform: rotate(-90deg);
    -webkit-transform-origin: left top;
    -moz-transform: rotate(-90deg);
    -moz-transform-origin: left top;
    -o-transform: rotate(-90deg);
    -o-transform-origin: left top;
    transform: translateX(50%) rotate(-90deg);
    transform-origin: left;
  }
}

table.collab-table {
  thead tr th:first-child,
  tbody tr td:first-child {
    width: 150px;
  }

  thead tr th:last-child,
  tbody tr td:last-child {
    width: 40px;
  }
}

.badge-orgs-connx {
  display: inline-block;
  padding: 3px 6px 4px;
  background-color: #0a377f !important;
  color: #fff !important;
  border-radius: 50%;
}

.badge-counts {
  display: inline-block;
  min-width: 10px;
  padding: 3px 6px 4px;
  vertical-align: middle;
  border-radius: 10px;
  font-size: 11px;
  background-color: #23c6c8 !important;
  color: #fff !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.bg-chart-1 {
  background-color: $chart-1;
}

.bg-chart-2 {
  background-color: $chart-2;
}

.bg-chart-3 {
  background-color: $chart-3;
}

.bg-chart-4 {
  background-color: $chart-4;
}

.bg-chart-5 {
  background-color: $chart-5;
}

.bg-chart-6 {
  background-color: $chart-6;
}

.bg-chart-7 {
  background-color: $chart-7;
}

.bg-chart-8 {
  background-color: $chart-8;
}

.bg-chart-9 {
  background-color: $chart-9;
}

.bg-chart-10 {
  background-color: $chart-10;
}

.bg-chart-11 {
  background-color: $chart-11;
}

.bg-chart-12 {
  background-color: $chart-12;
}

.bg-chart-13 {
  background-color: $chart-13;
}

.bg-chart-14 {
  background-color: $chart-14;
}

.bg-chart-15 {
  background-color: $chart-15;
}

.bg-chart-16 {
  background-color: $chart-16;
}

.bg-chart-17 {
  background-color: $chart-17;
}

.bg-chart-18 {
  background-color: $chart-18;
}

.bg-chart-19 {
  background-color: $chart-19;
}

.bg-chart-20 {
  background-color: $chart-20;
}

.bg-chart-21 {
  background-color: $chart-21;
}

.bg-chart-22 {
  background-color: $chart-22;
}

.bg-chart-23 {
  background-color: $chart-23;
}

.bg-chart-24 {
  background-color: $chart-24;
}

.bg-chart-25 {
  background-color: $chart-25;
}

.bg-chart-26 {
  background-color: $chart-26;
}

.bg-chart-27 {
  background-color: $chart-27;
}

.bg-chart-28 {
  background-color: $chart-28;
}

.bg-chart-29 {
  background-color: $chart-29;
}

.bg-chart-30 {
  background-color: $chart-30;
}

.bg-chart-31 {
  background-color: $chart-31;
}

.bg-chart-32 {
  background-color: $chart-32;
}

.bg-chart-33 {
  background-color: $chart-33;
}

.bg-chart-34 {
  background-color: $chart-34;
}

.bg-chart-35 {
  background-color: $chart-35;
}

.bg-chart-36 {
  background-color: $chart-36;
}

.bg-chart-37 {
  background-color: $chart-37;
}

.bg-chart-38 {
  background-color: $chart-38;
}

.bg-chart-39 {
  background-color: $chart-39;
}

.bg-chart-40 {
  background-color: $chart-40;
}

.stage-buttons {
  hr {
    width: 3rem;
    opacity: 1;
    border-top: 1px solid;
    border-bottom: 1px solid;
    color: $stages
  }
}

.chart-tooltip {
  background-color: white;
  border: 1px solid $text;
  border-radius: 0.25rem;
  padding: 0.2rem 0.4rem;
}

.color-box {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  border: 1px solid $text;
  border-radius: 0.25rem;
}

// subject comment text and issues
.mh-50 {
  max-height: 50% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}


.issue-row {
  .input-group {
    > .form-control-plaintext {
      position: relative; // For focus state's z-index
      flex: 1 1 auto;
      width: 1%;
      min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
      border-style: solid;
      border-color: #ced4da;
      border-width: 1px 1px 1px 0;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
}

.issue-response-layout {
  > .col {
    @extend .overflow-y-auto;
    max-height: calc(100vh - 2rem - 280px);
  }
}

.selected-mapping {
  color: $white !important;
  background-color: $primary !important;
}

textarea.auto-expand {
  resize: none;
  overflow: hidden;

  table & {
    margin-top: 5px !important;
    float: left !important;
  }
}

textarea.hide-resize {
  resize: none;
  &::-webkit-resizer {
    display: none;
  }
}

@for $i from 1 through 10 {
  td.indent-#{$i} {
    padding-left: 6.5 + ($i - 1) * 15px;
  }
}

quill-editor {
  .ql-toolbar.ql-snow {
    @extend .p-1;
  }
  .ql-editor {
    font-family: 'Open Sans', sans-serif !important;
  }
  &.disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .invalid {
    .ql-toolbar, .ql-container {
      border-color: var(--bs-form-invalid-border-color);
    }
  }
}

// PDFix
.viewer {
  .pdf-gray-bg {
    background-color: #AAA;
  }

  .pdf-page-text, .pdf-page-annots {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
  }

  .pdf-page-annots {
    pointer-events: none
  }

  .pdf-page-text div {
    position: absolute;
    display: block;
  }

  .pdf-obj-fixed span {
    padding: 0;
    margin: 0;
    cursor: text;
    color: transparent;
    font-family: sans-serif;
    direction: ltr;
    white-space: nowrap;
    text-shadow: none;
  }

  .pdf-page {
    background-color: white;
    display: block;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #AAA;
    padding: 10px;

    input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      margin: 0;
    }

    ::selection, ::-moz-selection {
      background: rgba(0, 100, 255, 0.3);
    }

    .pdf-page-inner {
      transform-origin: 0 0;
      transform: scale(1.0);
      background-repeat: no-repeat;
      background-size: contain;
    }

    .pdf-obj-fixed {
      display: flex;
      box-sizing: border-box;
    }

    .ff-ss {
      font-family: sans-serif;
    }

    .ff-s {
      font-family: serif;
    }

    .ff-m {
      font-family: monospace;
    }

    .acroform-field {
      background-color: rgba(210, 230, 250, 0.29);
      border: 0;
      pointer-events: auto;
    }

    .pdf-annot {
      pointer-events: auto;
    }

    textarea {
      resize: none;
    }

    input, select, textarea {
      padding: 0
    }

    h1, h2, h3, h4 {
      font-weight: inherit;
      line-height: 1.2
    }
  }

  .pdf-page.responsive {
    text-align: left;
    font-size: 1.08rem;
    background-color: white;
    font-weight: 400;
    color: $text;

    select, input, textarea {
      padding: 0 1px;
      min-width: 100px
    }

    input[type=checkbox] {
      min-width: 20px
    }

    p {
      white-space: pre-wrap;
      word-wrap: break-word;
      margin: 0;
    }

    .aleft {
      text-align: left;
    }

    .aright {
      text-align: right;
    }

    .acenter {
      text-align: center;
    }

    .atop {
      vertical-align: top;
    }

    .abottom {
      vertical-align: bottom;
    }

    .amiddle {
      vertical-align: middle;
    }

    .pde-table {
      overflow-x: auto;
      margin: .3em 0 .7em 0;
      padding-right: 1px;
      max-width: 100%;
    }

    .pde-text {
      margin: .3em 0 .7em 0;
    }

    table, .table {
      font-size: 90%;
      width: 100%;
      border-width: 0;
      border-style: solid;
      border-color: black;
      border-collapse: collapse;
      padding: 1px;

      td {
        padding: 5px;
        vertical-align: top;

        .bl {
          border-left-width: 1px;
          border-left-style: solid;
        }

        .br {
          border-right-width: 1px;
          border-right-style: solid;
        }

        .bt {
          border-top-width: 1px;
          border-top-style: solid;
        }

        .bb {
          border-bottom-width: 1px;
          border-bottom-style: solid
        }
      }

      p {
        font-size: inherit
      }

      .pde-text {
        margin: 0
      }

      .pde-image {
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100%
      }

      h1, .h2, .h3, .h4 {
        margin: 0;
      }
    }

    .title {
      font-size: 250%;
    }

    .pde-table-form td {
      padding: 0 1px;
    }

    .dropcap {
      float: left;
      font-weight: bold;
      font-size: 60px;
      line-height: 55px;
      padding-top: 4px;
      padding-right: 8px;
      padding-left: 2px;
    }

    .pde-line {
      display: block;
      height: 1px;
      margin: .3em 0 .7em 0;
    }

    .pde-image {
      max-width: 100%;
      margin-top: 5px;
      padding-top: 5px;
      padding-bottom: 5px;

      img {
        max-width: 100%
      }
    }

    .pde-image-inner {
      background-repeat: no-repeat;
      background-size: contain;
    }

    .pde-image-childs {
      width: 100%;
      height: 100%;
    }

    .pde-rect {
      max-width: 100%;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .pde-rect-inner {
      margin: 5px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .pde-image-float {
    float: left;
    margin-right: 10px;
  }

  .pdf-clear-float {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  .scratchpad {
    white-space: pre-wrap;
  }
}

//ng-select control

.ng-select {
  > .ng-select-container {
    font-size: 1.08rem !important;
    color: $text;
    border-color: var(--bs-border-color);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-height: 2.5rem;

    .ng-value-container {
      .ng-placeholder {
        color: $text;
      }
    }
  }

  &.ng-select-single > .ng-select-container {
    height: 2.5rem;
  }

  &.ng-select-opened > .ng-select-container {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow !important;
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $input-focus-border-color;
      outline: 0;
      box-shadow: $input-focus-box-shadow;
    }
  }

  &.is-invalid {
    > .ng-select-container {
      border-color: var(--bs-form-invalid-border-color);
    }

    &.ng-select-opened > .ng-select-container {
      border-color: var(--bs-form-invalid-border-color);
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;;
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container {
        border-color: var(--bs-form-invalid-border-color);
        box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
      }
    }
  }

  .ng-arrow-wrapper {
    .ng-arrow {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      width: 16px !important;
      height: 12px !important;
      border: 0;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          top: auto;
        }

        .ng-value {
          color: $text;
          padding: 0;
          background-color: unset;
          border: 1px solid $text;
          border-radius: 0.375rem;

          .ng-value-label {
            padding: 0.25rem 0 0.25rem 0.5rem;
          }

          .ng-value-icon.left {
            border: 0;
            float: right;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  &.ng-select-wrapped-labels {
    .ng-option-label {
      text-wrap: wrap;
    }

    .ng-value-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 170px;
      height: 20px;
    }
  }
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: 0;
  border: 0;
}

.ng-dropdown-panel {
  width: auto !important;
  min-width: 100%;

  .ng-dropdown-panel-items .ng-option {
    color: $text;

    &.ng-option-marked {
      background-color: $primary;
      color: $white;
    }
  }
}

.alert {
  &::before {
    font-weight: 900;
    text-align: center;
    vertical-align: middle;
    font-size: 1.25em;
    line-height: 0.05em;
    font-family: "Font Awesome 6 Free", serif;
    margin-right: 0.5em;
  }

  &.alert-primary::before {
    content: "\f05a";
    color: var(--bs-primary) !important;
  }

  &.alert-warning::before {
    content: "\f06a";
    color: var(--bs-warning) !important;
  }

  &.alert-danger::before {
    content: "\f056";
    color: var(--bs-danger) !important;
  }

  table {
    background-color: inherit;
    border-color: inherit;

    th, td {
      background-color: inherit;
      color: inherit;
    }
  }
}

#modal-tour-wrapper {
  .shepherd-element {
    z-index: 1070
  }

  .shepherd-modal-overlay-container {
    z-index: 1065
  }
}
