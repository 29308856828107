$min-contrast-ratio: 3;

//colours
$primary: #0088ca;
$secondary: #0a377f;
$danger: #ed5565;
$bg: #f3f3f4;
$text: #676a6c;
$light-text: #c6c6c6;
$topbar-text: #999c9e;
$white: #ffffff;

$best: #0088ca;
$good: #18a689;
$ok: #f8ac59;
$bad: #ed5565;
$worst: #9a3742;
$warn: #fff200;
$na: #bababa;
$roles: #0088ca;
$comments: #8c8c8c;
$other-comments: #bababa;
$stages: #626262;
$counts: #23c6c8;
$status: #bababa;
$connxns: #0a377f;
$tag: #857daa;
$reporting: #626262;
$keyword: rgba($worst, 0.7);
$cited: #ffff99;
$diff-new: rgba($good, 0.7);
$diff-update: rgba($ok, 0.7);
$diff-delete: rgba($bad, 0.85);

//Sidebar
$sidebar-bg: #293846;
$sidebar-text: $white;
$sidebar-text-muted: #bababa;
$sidebar-text-active: $white;
$sidebar-text-hover: #d7d7d7;

$input-plaintext-color: $text;

$input-focus-border-color: #80c4e5;
$input-focus-box-shadow: 0 0 0 0.25rem rgba(0, 136, 202, 0.25);

$table-color: $text;
$table-striped-bg-factor: .03 !default;

$modal-body-bg: #f8fafb;

$bs-popover-font-size: 5rem;

$chart-1: #0088ca;
$chart-2: #18a689;
$chart-3: #f8ac59;
$chart-4: #ed5565;
$chart-5: #8c8c8c;
$chart-6: #f3a36b;
$chart-7: #857daa;
$chart-8: #abc200;
$chart-9: #9a3742;
$chart-10: #0a377f;
$chart-11: #bababa;
$chart-12: #c17d2b;
$chart-13: #e19eff;
$chart-14: #b3ea7b;
$chart-15: #eed518;
$chart-16: #f451ae;
$chart-17: #8ec2e2;
$chart-18: #646464;
$chart-19: #ffc641;
$chart-20: #9f7de3;
$chart-21: #d4e157;
$chart-22: #d6ffa6;
$chart-23: #d2b4de;
$chart-24: #0aeafe;
$chart-25: #c1d5dc;
$chart-26: #c3adcc;
$chart-27: #a18fdb;
$chart-28: #bcaf7c;
$chart-29: #c34cae;
$chart-30: #afd7f4;
$chart-31: #ea6cc6;
$chart-32: #424343;
$chart-33: #ff9842;
$chart-34: #bfef58;
$chart-35: #2fedbd;
$chart-36: #90117e;
$chart-37: #1e96db;
$chart-38: #c13eb4;
$chart-39: #dfff41;
$chart-40: #e04180;

